input {
  margin: 10px;
}

h1,
h3,
h2,
p {
  color: white;
}

h1 {
  text-align: center;
}

html,
body {
  height: 100%;
  background-image: linear-gradient(to bottom, #021a3c, #043172);
}

#creds {
  font-size: 14px;
}

p.listitem {
  font-weight: bold;
}

span {
  text-decoration: underline;
}

input,
p {
  font-size: 15px;
}

button {
  width: 200px;
  margin: auto;
}
